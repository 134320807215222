import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import DropzoneInput from './DropzoneInput';
import PaperClip from '../../../images/global/paper-clip.svg';

const TO_MEGABYTES = 2 ** 20;

const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '38px',
  height: '38px',
  boxShadow: '0 3px 20px 0 rgba(25, 51, 106, 0.15)',
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  cursor: 'pointer',
};

function FileUpload({
  label,
  input: { onChange, value },
  meta: { invalid, touched, error },
  style,
  multiple,
  maxSize,
  onDropRejected,
  onDropAccepted,
  accept,
}) {
  const filenamePreview = () =>
    _.map(value, file => (
      <p
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginBottom: '0px',
        }}
        key={file.name}
      >
        {file.name}
        <br />
      </p>
    ));

  return (
    <div style={{ marginBottom: '50px', position: 'relative', ...style }}>
      <h3>{label}</h3>
      <p className="onboarding-sub-label" style={{ marginBottom: '20px' }}>
        <FormattedMessage
          defaultMessage="{extensions} types allowed. Max file size is {maxSize} MB"
          values={{
            extensions: accept,
            maxSize,
          }}
        />
      </p>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DropzoneInput
          accept={accept}
          component={
            <div style={buttonStyle}>
              <PaperClip />
            </div>
          }
          multiple={multiple}
          onChange={onChange}
          maxSize={maxSize * TO_MEGABYTES}
          onDropRejected={onDropRejected}
          onDropAccepted={onDropAccepted}
        />
        <div
          style={{
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            marginLeft: '20px',
          }}
        >
          {_.isEmpty(filenamePreview()) ? (
            <FormattedMessage defaultMessage="Choose Files" />
          ) : (
            filenamePreview()
          )}
        </div>
      </div>
      <span
        className="error-text"
        style={{ position: 'absolute', bottom: '-25px' }}
      >
        {touched && invalid ? error : ''}
      </span>
    </div>
  );
}

FileUpload.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  style: PropTypes.object,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  onDropRejected: PropTypes.func,
  onDropAccepted: PropTypes.func,
  accept: PropTypes.string,
};

FileUpload.defaultProps = {
  label: undefined,
  style: {},
  multiple: false,
  maxSize: 2,
  onDropRejected: () => {},
  onDropAccepted: () => {},
  accept: '.jpg, .jpeg, .png, .pdf',
};

export default FileUpload;
