import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GSCLogoEn from '../../../images/misc/gsc_logo_en.png';
import GSCLogoFr from '../../../images/misc/gsc_logo_fr.png';

const Logo = ({ locale }) => (
  <img
    style={{ width: '150px', height: '50px' }}
    src={locale === 'fr' ? GSCLogoFr : GSCLogoEn}
    alt={<FormattedMessage defaultMessage="GSC" />}
  />
);

Logo.propTypes = {
  locale: PropTypes.oneOf(['en', 'fr']).isRequired,
};

export default Logo;
