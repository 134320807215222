import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../images/global/spinner.svg';

const DataLoadingSpinner = () => (
  <div className="data-loading-spinner">
    <div className="dls-spinner">
      <SvgIcon color="primary">
        <Spinner />
      </SvgIcon>
    </div>
    <div className="dls-message">
      <FormattedMessage defaultMessage="Loading ..." />
    </div>
  </div>
);

export default DataLoadingSpinner;
