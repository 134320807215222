import React from 'react';
import PropTypes from 'prop-types';
import { COUNTRY } from '../../environment';
import Logo from './Logo';

const PreAuthTopBar = ({ locale }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
      position: 'relative',
      zIndex: '100',
      backgroundColor: 'white',
    }}
  >
    {/* <a href={`https://${COUNTRY === 'US' ? 'us.' : ''}inkblotpractice.com`}> */}
    <Logo locale={locale} />
    {/* </a> */}
  </div>
);

PreAuthTopBar.propTypes = {
  locale: PropTypes.oneOf(['en', 'fr']).isRequired,
};

export default PreAuthTopBar;
