import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import glowLoading from '../../../images/lottie/glow_loading.json';

const LoadingPanel = props => {
  const { message } = props;
  const anime = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: glowLoading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
    return () => lottie.stop();
  }, []);

  return (
    <div className="global-loading-panel">
      <div className="panel-wrapper">
        <div
          style={{ width: 200, height: 200, margin: 'auto' }}
          ref={anime}
        ></div>
        <div className="panel-message">
          {message || <FormattedMessage defaultMessage="Please Wait" />}
        </div>
      </div>
    </div>
  );
};

LoadingPanel.propTypes = {
  message: PropTypes.string,
};

export default LoadingPanel;
