/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { LanguageContext } from '../../LanguageProvider';

function LocaleSelectMini() {
  return (
    <LanguageContext.Consumer>
      {({ locale, setLocale }) => (
        <div className="language-selector-mini">
          <div
            role="button"
            className={locale === 'en' ? 'active' : ''}
            onClick={() => setLocale('en')}
            onKeyDown={() => setLocale('en')}
            tabIndex={0}
          >
            EN
          </div>
          |
          <div
            role="button"
            className={locale === 'fr' ? 'active' : ''}
            onClick={() => setLocale('fr')}
            onKeyDown={() => setLocale('fr')}
            tabIndex={-1}
          >
            FR
          </div>
        </div>
      )}
    </LanguageContext.Consumer>
  );
}

export default LocaleSelectMini;
